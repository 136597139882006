<template>
  <div id="app">
    <component :is="layout">
        <body-skeleton v-if="!initialized" />
        <transition name="fade" mode="out-in">
            <router-view></router-view>
        </transition>
    </component>
    <portal-target name="panel-right" multiple>
    </portal-target>
    <b-alert
      v-if="connectionProblem"
      show
      class="position-fixed fixed-top rounded-0 alert-connection"
      style="z-index: 2000;"
      variant="danger"
      dismissible
    >
        <i18n path="settings.integration.alert.message" tag="span">
                <template v-slot:name>
                    {{ connectionProblem.name }}
                </template>
                <template v-slot:link>
                    <a :href="connectionProblem.link">{{ $t('settings.integration.alert.message.link' )}}</a>
                </template>
            </i18n>
    </b-alert>
    <cached-warning />
    <homepage-modal />
    <updater />
    <exporter />
  </div>
</template>

<script>
  const default_layout = "default";
  import i18n from "@/i18n";
  import EventsBus from '@/libs/EventsBus';
  import BodySkeleton from './BodySkeleton';
  import HomepageModal from './modules/HomepageModal';
  import Updater from '@/Layout/Components/Updater';
  import Exporter from '@/Layout/Components/Exporter';
  import CachedWarning from '@/Layout/Components/CachedWarning';
  
  let alertEnabled = false;
  let homepageEnabled = false;

  const knownThemes = ['light', 'dark', 'blue'];

  export default {
    components: {
        BodySkeleton,
        HomepageModal,
        Updater,
        Exporter,
        CachedWarning
    },
    data: () => ({
        initialized: false,
        connectionProblem: null
    }),
    computed: {
      layout() {
        return (this.$route.meta.layout || default_layout) + '-layout';
      }
    },
    mounted() {
        EventsBus.once('initialized', async () => {
            this.initialized = true;
            this.setCurrentLanguage();
            this.verifyConnections();
            this.verifyHomepage();
        });
        EventsBus.on('notify', async ({ title, variant, text, autoHideDelay }) => {
            this.showNotification({ title, variant, text, autoHideDelay });
        });
        if (localStorage) {
            const themeSaved = localStorage.getItem('theme');
            this.selectTheme(themeSaved || 'light');
        }
    },
    methods: {
        showNotification({ title, variant, text, autoHideDelay }) {
            this.$bvToast.toast(text, {
                title,
                variant,
                autoHideDelay: autoHideDelay || 5000,
                appendToast: false
            });
        },
        async verifyConnections() {
            if (alertEnabled) return;
            // Alert error only for CRM connections
            await this.$store.dispatch("connections/ensureConnections");
            const mailConnections = this.$store.getters["connections/getConnects"].filter((c) => c.type === "MAIL");
            const erroredMailConnections = mailConnections.flatMap(t => this.getConnectionByType(t.id)).filter(c => c.status === 'ACTIVE' && c.userId === this.$userId() && c.lastExecutionStatus === 'ERROR');
            if (erroredMailConnections.length) {
                this.connectionProblem = {
                    name: erroredMailConnections[0].type.toLowerCase(),
                    link: this.$router.resolve({ name: mailConnections.find(t => t.id === erroredMailConnections[0].type)?.route })?.href
                };
                alertEnabled = true;
            }
            else {
                const crmConnections = this.$store.getters["connections/getConnects"].filter((c) => c.type === "CRM");
                const disabledCrmConnections = crmConnections.flatMap(t => this.getConnectionByType(t.id)).filter(c => c.status === 'DISABLED');
                if (disabledCrmConnections.length) {
                    this.connectionProblem = {
                        name: disabledCrmConnections[0].type.toLowerCase(),
                        link: this.$router.resolve({ name: crmConnections.find(t => t.id === disabledCrmConnections[0].type)?.route })?.href
                    };
                    alertEnabled = true;
                }
            }
        },
        getConnectionByType(type) {
            return this.$store.getters['connections/getAll']?.filter(c => c.type === type);
        },
        selectTheme(name) {
            if (name && !knownThemes.includes(name)) {
                throw new Error(`"${name}" has not been defined as a theme.`);
            }
            let body = document.body;        
            body.setAttribute("data-theme", name);
            localStorage.setItem("theme", name);
        },
        async verifyHomepage() {
            if (homepageEnabled) return;
            const hasViewMyAction = window.localStorage.getItem('myactions-visited');
            const config = this.$store.getters['configs/getForUserAll'];
            // Show homepage modal only if user has already visited MyAction page and if homepage is not defined and onboarding no more set
            if (hasViewMyAction && !config?.homepage && this.$store.getters['onboarding/getShow'] === false) {
                this.$nextTick(() => {
                    this.$bvModal.show('HomepageModal');
                });
            }
            homepageEnabled = true;
        },
        setCurrentLanguage() {
            const language = this.$store.getters['configs/getForUser']('language') || i18n.locale;
            EventsBus.emit('language', language);
        }
    }
  }
</script>

<style lang="scss">
  @import "assets/base.scss";

  .alert-connection {
    margin-left: $app-sidebar-width;
    min-height: $app-header-height;
    line-height: $app-header-height / 2 + 5;
  }
  .closed-sidebar {
    .alert-connection {
        margin-left: $app-sidebar-width-collapsed;
    }
  }
</style>
