<template>
    <!-- <div class="app-sidebar sidebar-shadow" @mouseover="toggleSidebarHover('add','closed-sidebar-open')" @mouseleave="toggleSidebarHover('remove','closed-sidebar-open')"> -->
    <div class="app-sidebar sidebar-shadow">
        <div class="app-header__logo">
            <div class="logo-src"/>
            <div class="header__pane expander">
                <button type="button" class="hamburger close-sidebar-btn hamburger--arrow" v-bind:class="{ 'is-active' : !isCollapsed }" @click="toggleBodyClass('closed-sidebar')">
                    <span class="hamburger-box">
                        <span class="hamburger-inner"></span>
                    </span>
                </button>
            </div>
        </div>
        <div class="app-sidebar-content">
            <!-- <VuePerfectScrollbar class="app-sidebar-scroll" v-once> -->
            <VuePerfectScrollbar class="app-sidebar-scroll">
                <sidebar-menu showOneChild :relative="relative" :menu="menu" :hide-toggle="true" @item-click="handleMenuClick" :collapsed="isCollapsed" />
                <div class="app-copyright text-center">
                    Copyright 2024 Skalin
                </div>
            </VuePerfectScrollbar>
        </div>

    </div>
</template>

<script>
    import i18n from '@/i18n';
    import { SidebarMenu } from 'vue-sidebar-menu';
    import VuePerfectScrollbar from 'vue-perfect-scrollbar';
    import EventsBus from '@/libs/EventsBus';

    const MENU = process.env.NODE_ENV === 'development' ? [
                    {
                        title: i18n.t('onboarding.title'),
                        icon: 'pe-7s-gleam',
                        href: '/onboarding',
                        alias: '/onboarding(.*)',
                        attributes: {
                            title: i18n.t('onboarding.title'),
                        },
                        authorisation: {
                            feature: 'onboarding',
                            action: 'read'
                        }
                    },
                    {
                        title: i18n.t('global.myactions.title'),
                        icon: 'pe-7s-star',
                        href: '/myactions',
                        alias: '/myactions(.*)',
                        attributes: {
                            title: i18n.t('global.myactions.title'),
                        },
                        authorisation: {
                            feature: 'myactions',
                            action: 'read'
                        },
                        // badge: {
                        //     text: 'Beta',
                        //     class:'badge-beta'
                        // }
                    },
                    {
                        header: true,
                        title: 'Global',
                    },
                    {
                        title: i18n.t('global.customers.title'),
                        icon: 'pe-7s-rocket',
                        href: '/customers',
                        alias: '/customers(.*)',
                        attributes: {
                            title: i18n.t('global.customers.title'),
                        }
                    },
                    {
                        title: i18n.t('global.contacts.title'),
                        icon: 'pe-7s-users',
                        href: '/contacts',
                        alias: '/contacts(.*)',
                        attributes: {
                            title: i18n.t('global.contacts.title'),
                        }
                    },
                    {
                        title: i18n.t('global.tasks.title'),
                        icon: 'pe-7s-check',
                        href: '/tasks',
                        alias: '/tasks(.*)',
                        attributes: {
                            title: i18n.t('global.tasks.title'),
                        }
                    },
                    {
                        title: i18n.t('global.alerts.title'),
                        icon: 'pe-7s-bell',
                        href: '/alerts',
                        alias: '/alerts(.*)',
                        // badge: {
                        //     text: 'Beta',
                        //     class:'badge-beta'
                        // },
                        attributes: {
                            title: i18n.t('global.alerts.title'),
                        }
                    },
                    {
                        title: i18n.t('global.playbooks.title'),
                        icon: 'pe-7s-play',
                        href: '/playbooks',
                        alias: '/playbooks(.*)',
                        // hidden: true,
                        // badge: {
                        //     text: 'Beta',
                        //     class:'badge-beta'
                        // },
                        attributes: {
                            title: i18n.t('global.playbooks.title'),
                        },
                        authorisation: {
                            feature: 'playbook',
                            action: 'read'
                        }
                    },
                    {
                        header: true,
                        title: 'Reports',
                    },
                    // {
                    //     title: 'Mrr',
                    //     icon: 'pe-7s-chart',
                    //     href: '/reports/mrr' 
                    // },
                    {
                        title: i18n.t('report.healthscore.title'),
                        icon: 'pe-7s-graph2',
                        href: '/reports/healthscore',
                        attributes: {
                            title: i18n.t('report.healthscore.title'),
                        }
                    },
                    {
                        title: i18n.t('report.renewal.title'),
                        icon: 'pe-7s-graph',
                        href: '/reports/renewal' ,
                        attributes: {
                            title: i18n.t('report.renewal.title'),
                        }
                    },
                    {
                        title: i18n.t('report.interaction-last-touch.title'),
                        icon: 'pe-7s-speaker',
                        href: '/reports/interaction-last-touch',
                        alias: '/reports/interaction-last-touch(.*)',
                        attributes: {
                            title: i18n.t('report.interaction-last-touch.title'),
                        },
                        // badge: {
                        //     text: 'New',
                        //     class:'badge-beta'
                        // }
                    },
                    {
                        title: i18n.t('report.mrr.title'),
                        icon: 'fa fa-sack-dollar fa-sm',
                        href: '/reports/mrr',
                        alias: '/reports/mrr(.*)',
                        badge: {
                            text: 'Beta',
                            class:'badge-beta'
                        },
                        attributes: {
                            title: i18n.t('report.mrr.title')
                        },
                        authorisation: {
                            feature: 'report.mrr',
                            action: 'read'
                        }
                    },
                    {
                        title: i18n.t('report.team.title'),
                        icon: 'pe-7s-diamond',
                        href: '/reports/team',
                        alias: '/reports/team(.*)',
                        attributes: {
                            title: i18n.t('report.team.title'),
                        },
                        authorisation: {
                            feature: 'report.team',
                            action: 'read'
                        }
                    },
                    {
                        title: i18n.t('global.goals.users.title'),
                        icon: 'fa fa-arrows-down-to-people fa-sm',
                        href: '/reports/goals/users',
                        alias: '/reports/goals/users(.*)',
                        badge: {
                            text: 'Beta',
                            class:'badge-beta'
                        },
                        attributes: {
                            title: i18n.t('global.goals.users.title'),
                        },
                        authorisation: {
                            feature: 'goal',
                            action: 'read'
                        }
                    },
                    {
                        title: i18n.t('global.goals.customers.title'),
                        icon: 'fa fa-users-viewfinder',
                        href: '/reports/goals/customers',
                        alias: '/reports/goals/customers(.*)',
                        badge: {
                            text: 'Beta',
                            class:'badge-beta'
                        },
                        attributes: {
                            title: i18n.t('global.goals.customers.title'),
                        },
                        authorisation: {
                            feature: 'goal',
                            action: 'read'
                        },
                        // hidden: true
                    },
                    {
                        title: i18n.t('report.time-profitability.title'),
                        icon: 'pe-7s-timer',
                        href: '/reports/time-profitability',
                        attributes: {
                            title: i18n.t('report.time-profitability.title'),
                        },
                        authorisation: {
                            feature: 'report.profitability',
                            action: 'read'
                        }
                    },
                    {
                        title: i18n.t('report.activityFeatures.title'),
                        icon: 'pe-7s-map',
                        href: '/reports/features',
                        alias: '/reports/features(.*)',
                        attributes: {
                            title: i18n.t('report.activityFeatures.title'),
                        }
                    },
                    {
                        header: true,
                        title: 'Settings',
                    },
                    {
                        title: i18n.t('settings.general.title'),
                        icon: 'pe-7s-config',
                        href: '/settings',
                        attributes: {
                            title: i18n.t('settings.general.title'),
                        }
                    },
                    {
                        title: i18n.t('settings.users.title'),
                        icon: 'pe-7s-user',
                        href: '/users',
                        alias: '/users(.*)',
                        attributes: {
                            title: i18n.t('settings.users.title'),
                        }
                    },
                    {
                        title: i18n.t('settings.settingsCustomerStages.title'),
                        icon: 'pe-7s-albums',
                        href: '/settings/customer-stages',
                        attributes: {
                            title: i18n.t('settings.settingsCustomerStages.title'),
                        }
                    },
                    {
                        title: i18n.t('settings.settingsCustomerProfiles.title'),
                        icon: 'pe-7s-edit',
                        href: '/settings/customer-profiles',
                        attributes: {
                            title: i18n.t('settings.settingsCustomerProfiles.title'),
                        }
                    },
                    {
                        title: i18n.t('global.goals.profiles.title'),
                        icon: 'pe-7s-target',
                        href: '/settings/goals',
                        alias: '/settings/goals(.*)',
                        badge: {
                            text: 'Beta',
                            class:'badge-beta'
                        },
                        attributes: {
                            title: i18n.t('global.goals.profiles.title'),
                        },
                        authorisation: {
                            feature: 'goal',
                            action: 'read'
                        }
                    },
                    {
                        title: i18n.t('settings.settingsInteractionGoals.title'),
                        icon: 'pe-7s-bell',
                        href: '/settings/interaction-goals',
                        attributes: {
                            title: i18n.t('settings.settingsInteractionGoals.title'),
                        }
                    },
                    {
                        title: i18n.t('settings.imports.title'),
                        icon: 'pe-7s-upload',
                        href: '/imports',
                        attributes: {
                            title: i18n.t('settings.imports.title'),
                        }
                    },
                    {
                        title: i18n.t('settings.integration.title'),
                        icon: 'pe-7s-play',
                        href: '/integration',
                        attributes: {
                            title: i18n.t('settings.integration.title'),
                        }
                    }
                ] : [
                    {
                        title: i18n.t('onboarding.title'),
                        icon: 'pe-7s-gleam',
                        href: '/onboarding',
                        alias: '/onboarding(.*)',
                        attributes: {
                            title: i18n.t('onboarding.title'),
                        },
                        authorisation: {
                            feature: 'onboarding',
                            action: 'read'
                        }
                    },
                    {
                        title: i18n.t('global.myactions.title'),
                        icon: 'pe-7s-star',
                        href: '/myactions',
                        alias: '/myactions(.*)',
                        attributes: {
                            title: i18n.t('global.myactions.title'),
                        },
                        authorisation: {
                            feature: 'myactions',
                            action: 'read'
                        },
                        // badge: {
                        //     text: 'Beta',
                        //     class:'badge-beta'
                        // }
                    },
                    {
                        header: true,
                        title: 'Global',
                    },
                    {
                        title: i18n.t('global.customers.title'),
                        icon: 'pe-7s-rocket',
                        href: '/customers',
                        alias: '/customers(.*)',
                        attributes: {
                            title: i18n.t('global.customers.title'),
                        }
                    },
                    {
                        title: i18n.t('global.contacts.title'),
                        icon: 'pe-7s-users',
                        href: '/contacts',
                        alias: '/contacts(.*)',
                        attributes: {
                            title: i18n.t('global.contacts.title'),
                        }
                    },
                    {
                        title: i18n.t('global.tasks.title'),
                        icon: 'pe-7s-check',
                        href: '/tasks',
                        alias: '/tasks(.*)',
                        attributes: {
                            title: i18n.t('global.tasks.title'),
                        }
                    },
                    {
                        title: i18n.t('global.alerts.title'),
                        icon: 'pe-7s-bell',
                        href: '/alerts',
                        alias: '/alerts(.*)',
                        // badge: {
                        //     text: 'Beta',
                        //     class:'badge-beta'
                        // },
                        attributes: {
                            title: i18n.t('global.alerts.title'),
                        }
                    },
                    {
                        title: i18n.t('global.playbooks.title'),
                        icon: 'pe-7s-play',
                        href: '/playbooks',
                        alias: '/playbooks(.*)',
                        // badge: {
                        //     text: 'Beta',
                        //     class:'badge-beta'
                        // },
                        attributes: {
                            title: i18n.t('global.playbooks.title'),
                        },
                        authorisation: {
                            feature: 'playbook',
                            action: 'read'
                        }
                    },
                    {
                        header: true,
                        title: 'Reports',
                    },
                    // {
                    //     title: 'Mrr',
                    //     icon: 'pe-7s-chart',
                    //     href: '/reports/mrr' 
                    // },
                    {
                        title: i18n.t('report.healthscore.title'),
                        icon: 'pe-7s-graph2',
                        href: '/reports/healthscore',
                        attributes: {
                            title: i18n.t('report.healthscore.title'),
                        }
                    },
                    {
                        title: i18n.t('report.renewal.title'),
                        icon: 'pe-7s-graph',
                        href: '/reports/renewal',
                        attributes: {
                            title: i18n.t('report.renewal.title'),
                        }
                    },
                    {
                        title: i18n.t('report.interaction-last-touch.title'),
                        icon: 'pe-7s-speaker',
                        href: '/reports/interaction-last-touch',
                        alias: '/reports/interaction-last-touch(.*)',
                        attributes: {
                            title: i18n.t('report.interaction-last-touch.title'),
                        },
                        // badge: {
                        //     text: 'New',
                        //     class:'badge-beta'
                        // }
                    },
                    {
                        title: i18n.t('report.mrr.title'),
                        icon: 'fa fa-sack-dollar fa-sm',
                        href: '/reports/mrr',
                        alias: '/reports/mrr(.*)',
                        badge: {
                            text: 'Beta',
                            class:'badge-beta'
                        },
                        attributes: {
                            title: i18n.t('report.mrr.title')
                        },
                        authorisation: {
                            feature: 'report.mrr',
                            action: 'read'
                        }
                    },
                    {
                        title: i18n.t('report.team.title'),
                        icon: 'pe-7s-diamond',
                        href: '/reports/team',
                        alias: '/reports/team(.*)',
                        attributes: {
                            title: i18n.t('report.team.title'),
                        },
                        authorisation: {
                            feature: 'report.team',
                            action: 'read'
                        },
                        // badge: {
                        //     text: 'New',
                        //     class:'badge-beta'
                        // }
                    },
                    {
                        title: i18n.t('global.goals.users.title'),
                        icon: 'fa fa-arrows-down-to-people fa-sm',
                        href: '/reports/goals/users',
                        alias: '/reports/goals/users(.*)',
                        badge: {
                            text: 'Beta',
                            class:'badge-beta'
                        },
                        attributes: {
                            title: i18n.t('global.goals.users.title'),
                        },
                        authorisation: {
                            feature: 'goal',
                            action: 'read'
                        }
                    },
                    {
                        title: i18n.t('global.goals.customers.title'),
                        icon: 'fa fa-users-viewfinder',
                        href: '/reports/goals/customers',
                        alias: '/reports/goals/customers(.*)',
                        badge: {
                            text: 'Beta',
                            class:'badge-beta'
                        },
                        attributes: {
                            title: i18n.t('global.goals.customers.title'),
                        },
                        authorisation: {
                            feature: 'goal',
                            action: 'read'
                        },
                        // hidden: true
                    },
                    {
                        title: i18n.t('report.time-profitability.title'),
                        icon: 'pe-7s-timer',
                        href: '/reports/time-profitability',
                        attributes: {
                            title: i18n.t('report.time-profitability.title'),
                        },
                        authorisation: {
                            feature: 'report.profitability',
                            action: 'read'
                        }
                    },
                    {
                        title: i18n.t('report.activityFeatures.title'),
                        icon: 'pe-7s-map',
                        href: '/reports/features',
                        alias: '/reports/features(.*)',
                        attributes: {
                            title: i18n.t('report.activityFeatures.title'),
                        },
                        // badge: {
                        //     text: 'New',
                        //     class:'badge-beta'
                        // },
                    },
                    {
                        header: true,
                        title: 'Settings',
                    },
                    {
                        title: i18n.t('settings.general.title'),
                        icon: 'pe-7s-config',
                        href: '/settings',
                        attributes: {
                            title: i18n.t('settings.general.title'),
                        }
                    },
                    {
                        title: i18n.t('settings.users.title'),
                        icon: 'pe-7s-user',
                        href: '/users',
                        alias: '/users(.*)',
                        attributes: {
                            title: i18n.t('settings.users.title'),
                        }
                    },
                    {
                        title: i18n.t('settings.settingsCustomerStages.title'),
                        icon: 'pe-7s-albums',
                        href: '/settings/customer-stages',
                        attributes: {
                            title: i18n.t('settings.settingsCustomerStages.title'),
                        }
                    },
                    {
                        title: i18n.t('settings.settingsCustomerProfiles.title'),
                        icon: 'pe-7s-edit',
                        href: '/settings/customer-profiles',
                        attributes: {
                            title: i18n.t('settings.settingsCustomerProfiles.title'),
                        }
                    },
                    {
                        title: i18n.t('global.goals.profiles.title'),
                        icon: 'pe-7s-target',
                        href: '/settings/goals',
                        alias: '/settings/goals(.*)',
                        badge: {
                            text: 'Beta',
                            class:'badge-beta'
                        },
                        attributes: {
                            title: i18n.t('global.goals.profiles.title'),
                        },
                        authorisation: {
                            feature: 'goal',
                            action: 'read'
                        }
                    },
                    {
                        title: i18n.t('settings.settingsInteractionGoals.title'),
                        icon: 'pe-7s-bell',
                        href: '/settings/interaction-goals',
                        attributes: {
                            title: i18n.t('settings.settingsInteractionGoals.title'),
                        }
                    },
                    {
                        title: i18n.t('settings.imports.title'),
                        icon: 'pe-7s-upload',
                        href: '/imports',
                        attributes: {
                            title: i18n.t('settings.imports.title'),
                        }
                    },
                    {
                        title: i18n.t('settings.integration.title'),
                        icon: 'pe-7s-play',
                        href: '/integration',
                        attributes: {
                            title: i18n.t('settings.integration.title'),
                        }
                    },
                ]

    export default {
        components: {
            SidebarMenu,
            VuePerfectScrollbar
        },
        data() {
            return {
                isCollapsed: false,
                sidebarActive: false,
                relative: true,
                windowWidth: 0,

            }
        },
        props: {
            sidebarbg: String,
            collapsed: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            menu() {
                return MENU.filter(m => {
                    return !m.authorisation || this.$isFeatureVisible(m.authorisation);
                }).map(m => {
                    return !m.authorisation || this.$isUserAllowed(m.authorisation) ? m : { ...m, badge: { text: '🔒', class:'badge-lock' }};
                });
            }
        },
        watch: {
            collapsed(value){
                this.isCollapsed = value;
            }
        },
        methods: {

            toggleBodyClass(className) {
                const el = document.body;
                this.isCollapsed = !this.isCollapsed;

                if (this.isCollapsed) {
                    el.classList.add(className);
                } else {
                    el.classList.remove(className);
                }
                this.$emit('update:collapsed', this.isCollapsed);
            },
            toggleSidebarHover(add, className) {
                const el = document.body;
                this.sidebarActive = !this.sidebarActive;

                this.windowWidth = document.documentElement.clientWidth;

                if (this.windowWidth > '992') {
                    if (add === 'add') {
                        el.classList.add(className);
                    } else {
                        el.classList.remove(className);
                    }
                }
            },
            getWindowWidth() {
                const el = document.body;

                this.windowWidth = document.documentElement.clientWidth;

                if (this.windowWidth < '992') {
                    this.isCollapsed = true;
                    this.$emit('update:collapsed', this.isCollapsed);
                    if (this.isCollapsed) el.classList.add('closed-sidebar');
                    el.classList.add('closed-sidebar-md');
                } else {
                    if (!this.isCollapsed) el.classList.remove('closed-sidebar');
                    el.classList.remove('closed-sidebar-md');
                }
            },
            handleMenuClick() {
                EventsBus.emit('menu-click');
            }
        },
        mounted() {
            this.isCollapsed = this.collapsed;
            this.$nextTick(function () {
                window.addEventListener('resize', this.getWindowWidth);

                //Init
                this.getWindowWidth()
            });
        },

        beforeDestroy() {
            window.removeEventListener('resize', this.getWindowWidth);
        }
    }
</script>
<style lang="scss">
.badge-beta {
    --color-pulser: var(--secondary);
    background-color: var(--color-pulser);
    border-radius: 5px;
    padding: 1px 5px;
    font-size: 10px;
    color: var(--dark);
    font-weight: normal;
}
.badge-lock {
    border-radius: 5px;
    padding: 1px 5px;
    font-size: 14px;
    color: var(--dark);
    font-weight: normal;
    margin-left: 5px;
}
.expander {
    display: flex;
    margin-left: auto;

    button {
        background-color: #202660;
        transform: scale(0.7);
    }
}
.closed-sidebar {
    .expander {
        margin-left: -8px;
    }
}
.vsm--link {
    .fa {
        font-size: 1.2rem !important;
        color: #c5cacf;
    }
    &.vsm--link_active {
        .fa {
            color: #FFF;
        }
    }
    &:hover {
        .fa {
            color: #aaa;
        }
    }
}
.app-copyright {
    font-size: 10px;
}
</style>