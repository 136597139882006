/* eslint-disable */
import dayjs from 'dayjs';
import { BaseInterceptor } from './BaseInterceptor';

const DEFAULT_PAGE_SIZE = 50;

function encodeValue(value) {
    return encodeURIComponent(value);
}

/**
 * Returns filter string from filter object
 * @param {*} filter
 * @param {string} field
 */
function getFilterString(filter, field) {
  let value = filter.value;
  if (value === null) value = 'null';
  // Format date value to ISO
  if (Object.prototype.toString.call(value) === '[object Date]') {
    value = dayjs(value)?.toDate().toISOString().replaceAll(':', '\\:');
  }
  switch(filter.type) {
    case 'contains':
      return `${field}:${encodeValue(value).replaceAll(':', '\\:')}:contains`;
    case 'bool':
      return `${field}:${value}:eq`;
    case 'enum':
        if (filter.operator === 'AND') {
            return value?.map((v) => {
                if ( v === null || typeof v === 'undefined') {
                  return null;
                }
                return `${field}:${v}:eq`;
            });
        }
        return value?.length ? `${field}:${value.map(v => v === null ? 'null' : v).join('|')}:in` : null;
    case 'numberrange':
      return value.map((v, index) =>{
        if ( v === null || typeof v === 'undefined') {
          return null;
        }
        const operator = index === 0 ? 'gte' : 'lte';
        return `${field}:${v}:${operator}`;
      });
    case 'daterange':
      return value.map((v, index) => {
        if ( v === null || typeof v === 'undefined') {
          return null;
        }
        const datestring = dayjs(v)?.toDate()
          .toISOString()
          .replaceAll(':', '\\:');

        const operator = index === 0 ? 'gte' : 'lte';
        return `${field}:${datestring}:${operator}`;
      });
  }
  if (filter.operator) return `${field}:${value}:${filter.operator}`;
  return `${field}:${encodeValue(value)}:eq`;
}

export function paginateQuery(query = {}, ctx = {}) {
  query.params = query.params || {};
  query.params.page = ctx.page || 1;
  query.params.size = ctx.size || DEFAULT_PAGE_SIZE;
  if (ctx.sort && ctx.sort.prop) {
    query.params.sort = `${ctx.sort.prop}.${ctx.sort.order === 'descending' ? 'desc' : 'asc' }`;
  }
  if (ctx.q) {
    query.params.q = ctx.q;
  }
  if (ctx.filters && Object.keys(ctx.filters).length) {
    query.params.filters = Object.keys(ctx.filters)
      .map(field => getFilterString(ctx.filters[field], field))
      .flat()
      .filter(filter => !!filter)
      .join(',');
  }
}


export class PaginationInterceptor extends BaseInterceptor {


  getType() {
    return 'PaginationInterceptor';
  }

  requestInterceptor() {
    return {
      request: config => {
        config = this.setPagination(config);
        return config;
      },
      error: error => {
        return Promise.reject(error);
      }
    };
  }
  responseInterceptor() {
    return {
      response: response => {
        return response;
      },
      error: async error => {
        throw error;
      }
    };
  }
  setPagination(config) {
    if (config.pagination) {
      paginateQuery(config, config.pagination);
    }
    return config;
  }
}
