
import Vue from 'vue';
import dayjs from 'dayjs';
import EventsBus from '@/libs/EventsBus';

const state = {
  fetched: {},
  agreements: {},
  stats: {}
};

const getters = {
  getAll: (state, getters, store, rootGetters) => customerId => {
      return state.agreements[customerId] || [];
  },
  getFromId: (state, getters) => (customerId, id ) => {
      return getters.getAll(customerId).find(a => a.id === id)
  },
  getStats: (state) => customerId => {
    return state.stats[customerId];
},
};

const actions = {

  async ensureAgreements({ dispatch, state }, { customerId }) {
    if (!state.fetched[customerId]) {
      await dispatch('fetchAgreements', { customerId });
    }
  },
  async ensureAgreementsStats({ dispatch, state }, { customerId }) {
    if (!state.stats[customerId]) {
      await dispatch('fetchAgreementsStats', { customerId });
    }
  },
  async fetchAgreements({ dispatch, commit }, { customerId }) {
    try {
      const data = (await this.getters.api.get(`/customers/${customerId}/agreements`)).map(fromAPI);
      commit('fetched', { customerId, value: true });
      commit('setAgreements', { customerId, data });
      return data;
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async fetchAgreementsStats({ dispatch, commit }, { customerId }) {
    try {
      const data = await this.getters.api.get(`/customers/${customerId}/agreements/stats`);
      commit('setAgreementsStats', { customerId, data });
    } catch (error) {
      throw 'A server error has occurred';
    }
  },
  async add({ commit }, { customerId, agreement }) {
    try {
        const data = await this.getters.api.post(`/customers/${customerId}/agreements`, { data: toAPI(agreement) });
        const result = fromAPI(data);
        commit('setAgreement', { customerId, data: result });
        await this.dispatch('customers/fetchCustomer', { customerId });
        return result;
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async update({ commit }, { customerId, agreementId, agreement }) {
    try {
        const data = await this.getters.api.patch(`/customers/${customerId}/agreements/${agreementId}`, { data:  toAPI(agreement) });
        // TODO remove
        agreement.id = agreementId;
        commit('setAgreement', { customerId, data: fromAPI(agreement) });
        await this.dispatch('customers/fetchCustomer', { customerId });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async remove({ commit }, { customerId, agreementId }) {
    try {
        await this.getters.api.delete(`/customers/${customerId}/agreements/${agreementId}`);
        commit('removeAgreement', { customerId, agreementId });
        await this.dispatch('customers/fetchCustomer', { customerId });
      } catch (error) {
        throw 'A server error has occurred';
      }
  },
  async import({ dispatch }, { agreements }) {
    const result = {
        count: 0,
        created: 0,
        updated: 0,
        deleted: 0,
        error: 0
    };
    try {
        const customers = await this.dispatch('customers/fetchCustomers');
        let total = 0;
        const agreementsByCustomer = agreements.reduce((prev, cur) => {
            const found = customers.find(c => (cur.customer?.length && c.refId?.toLowerCase() === cur.customer?.toLowerCase()) || (cur.customer?.length && c.domain?.toLowerCase() === cur.customer?.toLowerCase()) || (cur.customer?.length && c.name?.toLowerCase() === cur.customer?.toLowerCase()));
            if (found) {
                prev[found.id] = prev[found.id] || [];
                prev[found.id].push(cur);
                total++;
            }
            return prev;
        }, {});

        EventsBus.emit('agreements/import/start', {
            count: total
        });

        for(const customerId of Object.keys(agreementsByCustomer)) {
            const actualAgreements = await dispatch('fetchAgreements', { customerId });
            // Upset contacts
            for (const agreement of agreementsByCustomer[customerId].filter(c => !c.deleted)) {
                agreement.source = 'IMPORT';
                const found = actualAgreements?.find(c => (agreement.refId?.length && c.refId?.length && agreement.refId?.toLowerCase() === c.refId?.toLowerCase()) || (c.type === agreement.type && dayjs(agreement.startDate, 'YYYY/MM/DD').format('YYYY/MM/DD') === dayjs(c.startDate).format('YYYY/MM/DD')));
                if (found) {
                    const updated = { ...found };
                    if (agreement.mrr !== null) updated.mrr = agreement.mrr;
                    if (agreement.fee !== null) updated.fee = agreement.fee;
                    if (agreement.engagement !== null) updated.engagement = agreement.engagement;
                    if (agreement.engagementPeriod) updated.engagementPeriod = agreement.engagementPeriod;
                    if (agreement.notice !== null) updated.notice = agreement.notice;
                    if (agreement.noticePeriod) updated.noticePeriod = agreement.noticePeriod;
                    if (agreement.plan) updated.plan = agreement.plan;
                    if (agreement.status) updated.status = agreement.status?.toUpperCase();
                    updated.autoRenew = agreement.autoRenew;
            
                    try {
                        await dispatch('update', { customerId, agreementId: updated.id, agreement: updated });
                        result.updated++;
                    }
                    catch(ex) {
                        console.error(ex);
                        result.error++;
                    }
                }
                else {
                    try {
                        await dispatch('add', { customerId, agreement });
                        result.created++;
                    }
                    catch(ex) {
                        console.error(ex);
                        result.error++;
                    }
                }
                result.count++;
                console.info(agreement)
                EventsBus.emit('agreements/import/process', agreement);
            }
            // Delete contacts
            // for (const agreement of agreementsByCustomer[customerId].filter(c => !!c.deleted)) {
            //     const found = actualAgreements?.find(c => c.type === agreement.type && agreement.startDate.format('YYYY/MM/DD') === c.startDate.format('YYYY/MM/DD'));
            //     if (found) {
            //         try {
            //             await dispatch('remove', { customerId, agreementId: found.id });
            //             result.deleted++;
            //         }
            //         catch(ex) {
            //             console.error(ex);
            //             result.error++;
            //         }
            //     }
            //     result.count++;
            //     EventsBus.emit('contacts/import/process', agreement);
            // }
        }
        // Raz server redis cache
        if (total > 0) {
            this.dispatch('cache/resetServer');
        }
      } catch (error) {
        throw 'A server error has occurred';
      }
      return result;
  },
  reset({ commit, dispatch }) {
    dispatch('deleteAll');
    commit('reset');
  }
};

const mutations = {
  fetched(state, {  customerId, value }) {
    state.fetched[customerId] = value;
  },
  setAgreement(state, { customerId, data }) {
      const index = state.agreements[customerId].findIndex(c => c.id === data.id);
      if (index > -1) {
        Vue.set(state.agreements[customerId], index, { ...state.agreements[customerId][index], ...data});
      }
      else {
        state.agreements[customerId].push(data);
      }
  },
  setAgreements(state, { customerId, data }) {
    Vue.set(state.agreements, customerId, data);
  },
  removeAgreement(state, { customerId, agreementId }) {
    const index = state.agreements[customerId].findIndex(c => c.id === agreementId);
    if (index > -1) {
        state.agreements[customerId].splice(index, 1);
    }
  },
  setAgreementsStats(state, { customerId, data }) {
    Vue.set(state.stats, customerId, data);
  },
  reset(state) {
    for (const key of Object.keys(state.fetched)) {
      state.fetched[key] = false;
    }
  }
};

function fromAPI(json) {
    if (json.startDate) json.startDate = dayjs(json.startDate).toDate();
    if (json.endDate) json.endDate = dayjs(json.endDate).toDate();
    if (json.renewalDate) json.renewalDate = dayjs(json.renewalDate).toDate();
    return json;
}

function toAPI(json) {
    json.engagement = parseInt(json.engagement, 10);
    json.notice = parseInt(json.notice, 10);
    json.mrr = parseInt(json.mrr, 10);
    json.fee = parseInt(json.fee, 10);
    if (json.status) json.status = json.status?.toUpperCase();
    if (json.startDate) json.startDate = dayjs(json.startDate).format('YYYY/MM/DD');
    if (json.endDate) json.endDate = dayjs(json.endDate).format('YYYY/MM/DD');
    if (json.renewalDate) json.renewalDate = dayjs(json.renewalDate).format('YYYY/MM/DD');

    delete json.customer;
    return json;
}

export default {
  namespaced: true,
  getters,
  actions,
  state,
  mutations
};
