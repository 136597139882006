<template>
  <div id="exporter" class="exporter">
    <b-toast id="processing-export" v-model="exporting" variant="info" solid :no-auto-hide="true" :no-close-button="true">
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <b-icon icon="download" class="mr-2"></b-icon>
          <strong class="mr-auto">{{ $t('commons.export.inprogress.title') }}</strong>
          <small class="text-muted mr-2">{{ $tc('commons.period.ago.second', secondsAgo, { count: secondsAgo }) }}</small>
        </div>
      </template>
      <b-spinner type="grow" :small="true" class="mr-2" /> {{ $tc('commons.export.inprogress', exports.length, { count: exports.length })}}
    </b-toast>
  </div>
</template>
<script>
import EventsBus from '@/libs/EventsBus';

export default {
    components: {
    },
    data() {
        return {
            // refresh variables
            exports: [],
            startAt: null,
            exporting: false,
            secondsAgo: 0,
            timerSecond: null
        }
    },
    props: {
    },
    computed: {
    },
    created() {
        EventsBus.on('beginExport', (params) => {
            this.exports.push(params);
            this.exporting = true;
            if (!this.startAt) {
                this.startAt = new Date();
                this.secondsAgo = 0;
                this.timerSecond = setInterval(() => {
                    this.secondsAgo++;
                }, 1000);
            }
        });
      EventsBus.on('endExport', ({ id, result }) => {
        const index = this.exports.findIndex(c => c.id === id);
        if (index > -1) {
            this.showExportCompleted({ params: this.exports[index], result });
        
            this.exports.splice(index, 1);
        }
        this.exporting = this.exports.length > 0;
        if (!this.exporting) {
            this.startAt = null;
            clearInterval(this.timerSecond);
        }
      });
  },
  methods: {
    async waitExport(exportId) {
      const result = await this.$store.dispatch('exports/waitExport', { exportId });
      console.info(result);
      //   const url = window.URL.createObjectURL(new Blob([csv]));
      const link = document.createElement("a");
      link.href = result.link;
      link.setAttribute("download", "contacts.csv");
      document.body.appendChild(link);
      link.click();
    },
    showExportCompleted({ params, result }) {
        if (result.status === 'SUCCESS') {
            // Use a shorter name for this.$createElement
            const h = this.$createElement
            // Create the message
            const vNodesMsg = h(
                'div',
                { class: ['mb-0'] },
                [
                    this.$t(`commons.export.${params.query}.completed`),
                    h('br'),
                    [result].map(e => {
                        return h('a', { class: ['mr-2'], attrs: { href: e.link } }, [h('strong',{ class: ['mb-0'] }, this.$t('commons.export.click'))]);
                    })
                ].flat()
            )
            this.$bvToast.toast([vNodesMsg], {
                title: this.$t("commons.export.success"),
                variant: 'info',
                noAutoHide: true,
                appendToast: false
            });
        }
        else if (result.status === 'ERROR') {
            this.$bvToast.toast(i18n.t("commons.something.wrong"), {
                title: this.$t("commons.export.error"),
                variant: 'danger',
                noAutoHide: true,
                appendToast: false
            });
        }
    }
  }
}
</script>

<style lang="scss">
@import "../../assets/layout/_layout-variables.scss";

#exporter {
    .alert {
        margin-bottom: 0;
        padding-bottom: 5px;
        padding-top: 5px;
    }

    .spinner-border {
        position:absolute;
        width: 50px;
        height: 50px;
        margin: 0 !important;
    }
    img {
        width: 50px;
        height: 50px;
        margin: 0 !important;
    }
}
</style>
